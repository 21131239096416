@import "../../styles/container";
@import "../../styles/font-styles";
.rightBarTitle {
  padding: var(--spacer-16);
  display: flex;
  align-items: center;
  font-size: 20px;
  border-bottom: 1px solid #d9d9d9;
  min-height: 72px;
}

.homeRender {
  min-height: inherit;
}

.storageData {
  overflow: scroll;
  height: 100%;
}

.actionBlock {
  display: flex;
  align-items: center;
}

.originSettings {
  z-index: 5;
  background: #ffffff;
  border-bottom: 1px solid #efefef;
  width: 100%;
  border-radius: 10px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacer-24);
}

.originContent {
  display: flex;
  gap: var(--spacer-24);
  height: 100%;
}

.originTable {
  flex: 1;
  position: relative;
  margin-right: var(--spacer-24);
}

.textBlock {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 12px;
  }
}

.textBlockDisabled {
  --app-icon-color:  var(--grey-400);
  color: var(--grey-400);
  cursor: not-allowed;
}

.rightBarButton {
  display: flex;
  gap: var(--spacer-8);
  justify-content: center;
}

.originBlock {
  align-items: center;
  gap: var(--spacer-8);
  display: flex;
  @include font-100();
  //span {
  //  @include short-text(80%)
  //}
}

.divider {
  border-right: 1px solid #d9d9d9;
  margin: 0 20px;
  height: 34px;
}
.originEmpty {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font-100();
}
.saveContainer {
  background-color: var(--white);
  display: flex;
  gap: var(--spacer-16);
  align-items: center;
  position: absolute;
  bottom: 90px;
  left: 0;
  width: 100%;
  padding: var(--spacer-24) 0;
}
