@import "../../styles/font-styles";

.tabs {
  height: 100%;
  margin-top: var(--spacer-24);

  :global {
    .ant-tabs-content-holder,
    .ant-tabs-content,
    .ant-tabs-tabpane {
      height: 100%;
    }
  }
}

.tabBar {
  padding: 0 var(--spacer-16);

  :global {
    .ant-tabs-tab {
      @include font-50();
      padding: var(--spacer-12) var(--spacer-16);
    }
  }
}
