.textBlock {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 12px;
  }
}

.textBlockDisabled {
  --app-icon-color: var(--grey-400);
  color: var(--grey-400);
  cursor: not-allowed;
}

.divider {
  border-right: 1px solid #d9d9d9;
  margin: 0 20px;
  height: 34px;
}

.label {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu {
  border-radius: 5px;
}

.statusBlock {
  display: flex;
  align-items: center;
  color: #9B9B9B;
  font-size: 10px;
}

.statusTitle {
  color: #9B9B9B;
  margin-right: 4px;
}