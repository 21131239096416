@import "../../../styles/font-styles";
.originTabsWrapper {
  width: 360px;
  padding: 0 var(--spacer-24) var(--spacer-24) var(--spacer-24);
  border-right: 1px solid var(--grey-400);
}

.originTabList {
  position: relative;
  height: 100%;
  margin-top: 28px;
}

.originTabs {
  width: inherit;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-16);
  margin-bottom: 24px;
  overflow-y: scroll;
  height: calc(100% - 170px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.emptyTabs {
  display: flex;
  width: 100%;
  @include font-100();
  height: 400px;
  align-items: center;
  justify-content: center;
}
.originTab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacer-16);
  border-radius: var(--radius-12);
  border: 1px solid var(--grey-400);
  cursor: pointer;
  & > h3 {
    @include font-100();
  }
  &._active {
    border: 1px solid var(--accent-first);
    .tabIcon {
      --app-icon-color: var(--accent-first);
    }
  }
}

:global {
  .searchInputStorage {
    .ant-input-lg {
      padding: 12px 14px;
      border-radius: 4px 0 0 4px !important;
    }

    .ant-input-search-button {
      height: 50px !important;
      border-radius: 0 4px 4px 0 !important;
    }
  }
}
