@import "../../../styles/font-styles";
.formTitles {
  @include font-300();
  margin-bottom: var(--spacer-16);
  margin-right: var(--spacer-10);
  color: #6a6a6a;
}

.addSourceForm {
  --app-icon-color: var(--accent-first);
}

.addFileRender {
  margin-bottom: 30px;
}

.xlsxHeader {
  display: flex;
  justify-content: space-between;
}

.xlsxHeaderLeft {
  display: flex;
}

.xlsxHeaderRight {
  color: #6a6a6a;
}

.xlsxLines {
  color: rgba(0, 87, 255, 1);
}

.rangeBlock {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.rangeBlockCenter {
  height: 2px;
  width: 20px;
  background-color: #eaeaea;
}

.rangeBlockInputWrapper {
  min-height: 50px;
}

.inputError {
  color: var(--danger);
  margin-bottom: 32px;
}
.storageCheckbox {
  margin-bottom: 28px;
}

.errorText {
  color: var(--danger);
  margin-bottom: var(--spacer-16);
}
