@import "../../../../styles/font-styles";


.tab {
  @include font-50();
}

.table {
  padding-top: 16px;
  height: calc(100% - 32px) ;
  overflow: auto;
  padding-bottom: 56px;
  max-height: inherit;
}

.rowWrapper {
  padding: 0 var(--spacer-4);
}

.tableTitle {
  @include font-25();
}

.tableContent {
  @include font-25();

}

.footerTable {
  padding-bottom: 56px;
  margin-bottom: 16px;
  position: relative;
  height: 100%;
  max-height: inherit;
  &Close {
    position: absolute;
    right: 0;
    top: 6px;
    cursor: pointer;
    z-index: 20;
  }
}
.dragZone {
  height: 8px !important;
  position: relative;
  border-radius: var(--radius-4);
  display: flex;
  flex-direction: column;
  width: calc(100% + 48px);
  transform: translateX(-24px);
  &Container {
    height: 200px;
  }
  &::before, &::after {
    position: absolute;
    content: '';
    background-color: #e7e7e7;
    height: 1px;
    width: inherit;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}
