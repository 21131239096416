@import "../../../styles/font-styles";

.container {
  border: 1px solid #e8e0e0;
  border-radius: 4px;

  & > section:first-child {
    border-bottom: 1px solid #e8e0e0;
  }
}

.wrapper {
  display: flex;
  padding: var(--spacer-16);
  @include font-25();
}

.icon {
  --app-icon-color: var(--accent-first);
  margin-right: 4px;
  display: flex;
}

.title {
  @include font-400();
}
