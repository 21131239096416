.notificationContainer {
  display: flex;
  align-items: center;
}

.rightBlock {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-8);
  margin-left: 16px;
}

