@import "../../../styles/font-styles";
@import "../../../styles/btn-styles";

.card {
  border: 1px solid #e8e0e0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  --app-icon-color: var(--accent-first);

  position: relative;
  &._danger {
    border: 1px solid var(--danger);
  }

  .cardContentWithTitle {
    cursor: pointer;

    .cardBody{
      height: 100%;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowContainer {
  padding: var(--spacer-16);
}

.header {
  @include font-400();
  @include short-text(100%);
  border-bottom: 1px solid #e8e0e0;
}

.actions {
  @include font-400();
  border-top: 1px solid #e8e0e0;
}

.actionBtn {
  --app-icon-color: var(--accent-first);
  display: flex;
  cursor: pointer;

  @include reset-button();
}

.actionItem {
  display: flex;
  align-items: center;
  gap: var(--spacer-4);

  &._danger {
    color: var(--danger-200);

    svg {
      path {
        fill: var(--danger-200);
      }
    }
  }
  &._primary {
    color: var(--accent-first);
    svg {
      path {
        fill: var(--accent-first);
      }
    }
  }
  &._disabled {
    color: var(--grey-400);
    cursor: not-allowed;
  }
}

.boxLabel {
  @include font-50();
  margin-bottom: var(--spacer-8);
  color: var(--grey-500);
}

.boxContent {
  @include font-50();
  color: var(--grey-900);
}
