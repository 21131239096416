@mixin font-25() {
  font-weight: var(--font-300);
  font-size: 12px;
  line-height: 16px;
  color: var(--grey-900);
}
@mixin font-50() {
  font-weight: var(--font-300);
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-900);
}
@mixin font-100() {
  @include font-400-bold();
  font-weight: var(--font-300);
}

@mixin font-100-bold() {
  @include font-400-bold();
  font-weight: var(--font-500);
}

@mixin font-200() {
  font-size: 18px;
  line-height: 18px;
  font-weight: var(--font-300);
}

@mixin font-300() {
  color: var(--grey-900);
  font-size: 18px;
  line-height: 24px;
  font-weight: var(--font-500);
}
@mixin font-400() {
  font-weight: var(--font-300);
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
}
@mixin font-400-bold() {
  font-weight: var(--font-300);
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-900);
}
@mixin font-600() {
  font-weight: var(--font-500);
  font-size: 24px;
  line-height: 36px;
  color: var(--grey-900);
}

@mixin short-text($width: auto) {
  width: $width;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}




