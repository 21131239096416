@import './font-styles';

.introjs-tooltipbuttons {
  .introjs-button {
    font-family: 'Rubik', sans-serif !important;
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 8px 12px !important;
    border: 1px solid var(--accent-first) !important;
    outline: none;
    text-shadow: none;

  }
  .introjs-nextbutton {
    background: var(--accent-first) !important;
    color: var(--white) !important;
  }
  .introjs-prevbutton {
    background: var(--white) !important;
    color: var(--accent-first) !important;
  }
}

.introjs-tooltiptext {
  padding: var(--spacer-16) !important;
  font-family: 'Rubik', sans-serif !important;
  @include font-50();
}

.introjs-bullets ul li a {
  &.active {
    background: var(--accent-first) !important;
  }
}
