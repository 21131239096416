@import "../../../styles/font-styles";

.asideBody {
  padding: var(--spacer-16) 0;
}

.searchWrapper {
  padding: 0 var(--spacer-16);
}

.searchInputWrapper {
  --app-input-wrapper-height: 32px;
  --app-input-icon-top: 11px;
  padding-bottom: var(--spacer-16);
  border-bottom: 1px solid #d9d9d9;
}

.searchInput {
  padding: 10px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.asideList {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-8);
  overflow: auto;
  padding: 0 var(--spacer-16);

  &._nodeList {
    height: 250px;
    padding: var(--spacer-16);
  }
}

.asideItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacer-4) var(--spacer-8);
  border: 1px solid #eaeaea;
  border-radius: var(--radius-4);
  transition: ease-in-out 0.2s;
  background-color: #fff;
  cursor: grab;
  &:hover {
    background-color: var(--grey-200);
  }
}
.asideItemisUsed {
  border-color: var(--accent-first);
}

.asideItemisUsed2 {
  opacity: 0.4;
}

.asideItemType {
  color: var(--grey-500);
  font-size: var(--spacer-10);
  font-style: normal;
  font-weight: 300;
  line-height: var(--spacer-10);

  span {
    color: var(--accent-first);
  }
}

.asideItemCol {
  display: flex;
  align-items: center;
  gap: var(--spacer-8);
  width: 85%;
  padding: 0 4px;
}

.asideItemTitle {
  @include font-25();
  color: var(--black);

  @include short-text(100%);
}
.asideItemContainer {
  width: 100%;
  overflow-x: hidden;
  margin-left: 3px;
}

.asideItemSlug {
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #9b9b9b;
  margin-top: var(--spacer-4);

  @include short-text(100%);
}

.asideItemStatus {
  @extend .asideItemSlug;
  color: var(--green);
  margin-top: var(--spacer-2);
}

.transfromIcon {
  --app-icon-color: var(--accent-first);
}

.spinWrapper {
  text-align: center;
  margin-top: 18px;
  :global {
    .ant-spin-dot-item {
      background-color: var(--accent-first);
    }
  }
}

.internalSourcesTitle {
  color: #0057FF;
  font-weight: 300;
}

.externalSources {
  height: auto !important;
  .externalSourcesTitle {
    color: #00B21D;
    font-weight: 300;
  }
}
