@import "../../styles/font-styles";

.maintainContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-8);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  flex-direction: column;
  @include font-50();
}
