.container {
  display: grid;
  grid-template-columns: 32% 36% 32%;
}

.column {
  padding: var(--spacer-16) var(--spacer-24);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  &._divider {
    border-left: 1px solid #d9d9d9;
  }
}

.info {
  margin-bottom: var(--spacer-24);
}

.infoTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin: var(--spacer-16) 0;
}

.infoDescription {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}

.startIcon {
  --app-icon-color: var(--accent-first);
}