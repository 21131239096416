@import "../../../styles/font-styles";
.formTitles {
  @include font-300();
  margin-bottom: var(--spacer-16);
}

.authForm {
  width: 100%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: var(--spacer-16);
}

.error {
  @include font-50();
  color: var(--danger);
}
