@import "../../../styles/font-styles";

.originTable {
  height: 100%;
  position: relative;
}

.originTableSearch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchInput {
  flex: 1;
  height: 100%;
  margin-right: var(--spacer-16);
}
.searchButton {
  align-self: baseline;
  display: flex;
  justify-content: space-between;
  gap: var(--spacer-8);
}
.inputButton {
  height: 100%;
}

.originListWrapper {
  position: relative;
  height: 100%;
}

.originList {
  margin-bottom: 24px;
  overflow-y: scroll;
  height: calc(100% - 240px);
  padding-bottom: var(--spacer-24);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  * > thead {
    position: sticky;
    top: 0;
    z-index: 50;
  }
}

.originAttr {
  display: flex;
  flex-direction: column;
  span {
    font-size: 10px;
    line-height: 12px;
    color: var(--grey-500);
  }
  p {
    @include font-50();
  }
}

.originSync {
  display: flex;
  align-items: center;
  gap: var(--spacer-8);
}

.rowWrapper {
  padding: var(--spacer-8);
}

.tableTitle {
  @include font-50();
  color: var(--grey-500);
}
.ruleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.importFileAction {
  display: flex;
  align-items: center;
  gap: var(--spacer-4);
  font-size: 12px;
  line-height: 12px;
}
.actionDisabled {
  color: var(--grey-400);
  cursor: not-allowed;
}

.emptyTable {
  display: flex;
  width: 100%;
  height: 400px;
  @include font-100();
  align-items: center;
  justify-content: center;
}

.tableModalHeader {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-8);
  & > span {
    @include font-300();
  }
}

.modalEntity {
  @include font-50();
  span {
    color: var(--grey-900);
    &:last-of-type {
      color: var(--accent-first);
    }
  }
}

.ruleTag {
  background: rgba(0, 87, 255, 0.2);
}

.rowActions {
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: var(--spacer-8);
  span {
    color: var(--grey-400);
  }
}

:global {
  .searchInputStorageAttr {
    margin-right: 16px;
    margin-bottom: 28px;
    flex: 1 1;

    .ant-input-lg {
      padding: 12px 14px;
      border-radius: 4px 0 0 4px !important;
    }

    .ant-input-search-button {
      height: 50px !important;
      border-radius: 0 4px 4px 0 !important;
    }
  }
}
