@import "../../../../styles/font-styles";
.originTab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacer-16);
  border-radius: var(--radius-12);
  border: 1px solid var(--grey-400);
  cursor: pointer;
  & > h3 {
    @include font-100();
  }
  &._unsaved {
    background: rgba(255, 184, 0, 0.1);
    border: 1px solid rgba(255, 184, 0, 0.5);
  }
  &._active {
    border: 1px solid var(--accent-first);
    & > h3 {
      font-weight: 500;
    }
    .icon {
      --app-icon-color: var(--accent-first);
    }
  }
}

.iconSync {
  --app-icon-color: var(--accent-first);
}

.tabIcons {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tabSubtitle {
  color: var(--grey-500);
  span {
    color: var(--accent-first);
  }
}
