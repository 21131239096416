@import "../../styles/container";
@import "../../styles/font-styles";

.navigation {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-16);
}

.collapsed {
  color: red;

  .app-navigation_navBlock {
    width: 56px;
  }
}

.navBlock {
  width: 350px;
  transition: width 1s;
  position: relative;
  padding: var(--spacer-24) var(--spacer-16);
  display: flex;
  align-items: center;
  gap: var(--spacer-8);
  @include font-200();
  color: var(--grey-500);
  @include baseContainer();
  border: 1px solid var(--grey-300);
  &._maintain {
    filter: grayscale(60%);
    pointer-events: none;
    cursor: not-allowed;
  }
}

.navBlockCollapsed {
  width: 56px;
  justify-content: center;
}

.navBtnBlock {
  display: flex;
  justify-content: flex-end;
}

.navBtn {  transition: width 1s;
  padding: var(--spacer-24) var(--spacer-16);
  display: flex;
  justify-content: space-between;
  @include font-200();
  color: var(--grey-500);
  @include baseContainer();
  border: 1px solid var(--grey-300);
  cursor: pointer;
}

.navBtnCollapsed {
  width: 56px;
  justify-content: center;
}

.underMaintain {
  position: absolute;
  right: 20px;
  @include font-50();
  color: var(--grey-400);
}

.navBlockActive {
  color: var(--grey-900);
  background: rgba(var(--accent-second-rgb), 0.2);
}
