@import "../../../styles/font-styles";
.languageWrapper {
  padding: var(--spacer-24);
  @include font-100();
  cursor: pointer;
  border-left: 1px solid var(--grey-300);
  transition: ease-in-out 0.2s;
  width: 100px;
  &:hover {
    background: var(--grey-200);
  }
}
.languageData {
  display: flex;
  gap: var(--spacer-8);
  align-items: center;
}
.languageVariant {
  padding-left: var(--spacer-4);
}
