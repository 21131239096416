.container {
  width: 70px;
  height: 40px;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.loader {
  width: 220px;
  height: 220px;
}