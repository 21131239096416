@import "../../styles/font-styles";

.radioWrapper {
  @include font-100();
  label {
    padding: 0 0 12px;
  }
}

.radioGroup {
  display: flex;
  &._horizontal {
    flex-direction: row;
  }
  &._vertical {
    flex-direction: column;
  }
}
