.searchInput {
  height: unset;
  min-height: 50px;
}
.formButton {
  margin-left: 16px;
  margin-top: 8px;
}
.modalForm{
  display: flex;
  justify-content: space-between;
}