/* 
Генератор отступов, по заданной коллекции добавляет глобальные стили для отступов 
    .ma-0 { margin: 0 }
    .mt-0 { margin-top: 0 }
    .ml-0 { margin-left: 0 }
    .mh-0 { margin-left: 0; margin-right: 0 }
    .mv-0 { margin-top: 0; margin-bottom: 0 }
    .pa-5 { padding: 5px }
    .pt-5 { padding-bottom: 5px }
    .pl-5 { padding-right: 5px }
    .ph-5 { padding-left: 5px; padding-right: 5px }
    .pv-5 { padding-top: 5px; padding-bottom: 5px }
 */

$spacing: 0, 4, 8, 12, 16, 24, 32;

$directions:(
  l: left,
  t: top,
  r: right,
  b: bottom,
  v: top,
  h: left
);
@mixin generateSpacing($prop, $short  ) {
   @each $space in $spacing{
    .#{$short}a-#{$space} { #{$prop}: var(--spacer- + $space)}
    @each $key,$val in $directions{
      .#{$short}#{$key}-#{$space} {
        #{$prop}-#{$val}: var(--spacer- + $space);
        @if $key == v {
          #{$prop}-bottom: var(--spacer- + $space);
        }
        @if $key == h {
          #{$prop}-right: var(--spacer- + $space);
        }
      }
    }
  }
}
@include generateSpacing(padding , p);
@include generateSpacing(margin , m);