@import "../../../../styles/font-styles";

.footerTable {
  margin-bottom: 16px;
  position: relative;
  height: 100%;
  max-height: inherit;
  &Close {
    position: absolute;
    right: 0;
    top: 5px;
    cursor: pointer;
    z-index: 20;
  }
}

.processInfo {
  padding: 10px 8px 6px;
  border-bottom: 1px solid #d9d9d9;
  &Wrapper {
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding: 24px 24px 0 24px;
    margin-top: -24px;
    margin-left: -24px;
  }
  &Title {
    display: flex;
    justify-content: space-between;
  }
  &Content {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-4);
    font-size: 16px;
    & > span {
      font-size: 14px;
      color: #9b9b9b;
    }
  }
  & + .processInfo {
    margin-top: 20px;
  }
}

.tableWrapper {
  max-height: 400px;
  border-bottom: 1px solid #d9d9d9;
  overflow-y: auto;
  padding-left: 14px;
  padding-bottom: 14px;

  h3 {
    display: flex;
    gap: var(--spacer-10);
    margin: 14px;
    font-size: 16px;
    color: var(--grey-900);
    font-weight: 400;
  }
}

.rowWrapper {
  padding: 0 var(--spacer-4);
}

.tableTitle {
  @include font-25();
}

.tableContent {
  @include font-25();
}

.ruleWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacer-8);
}

.ruleTag {
  background: rgba(0, 87, 255, 0.2);
  margin-right: unset;
}

.status {
  color: var(--green);
  font-size: 14px;
}

.divider {
  height: 100%;
  color: black;
  background: black;
}
