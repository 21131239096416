@import "../../../styles/font-styles";

.originBlock {
  border: 1px solid var(--grey-400);
  border-radius: 4px;
  display: block;
  text-decoration: none;
  --app-icon-color: var(--accent-first);
}

.rowContainer {
  padding: var(--spacer-8) var(--spacer-16);
  border-bottom: 1px solid var(--grey-400);
}

.originRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.originRowTitle {
  &._truncate {
    @include short-text(80%);
  }
}
.originColor {
  width: 24px;
  height: 8px;
  border-radius: 3px;
}

.originRefresh {
  @include font-50();
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacer-4);
  margin: var(--spacer-8) 0;
  border-radius: var(--radius-8);
  --app-icon-color: var(--grey-500);
}
.originBlockHover {
  -webkit-transition: ease-in-out 0.2s;
  -moz-transition: ease-in-out 0.2s;
  -ms-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
  &:hover {
    background-color: var(--grey-200);
  }
}

.originWrapper {
  padding: var(--spacer-8) var(--spacer-16);
  background-color: var(--white);
  transition: ease-in-out 0.2s;
  &:hover {
    background: rgba(var(--accent-second-rgb), 0.1);
    .originRefresh {
      --app-icon-color: var(--accent-first);
    }
  }
}
.actionWrapper {
  border-top: 1px solid var(--grey-300);
}

.onLoading {
  animation: yourAnimation 1s linear 0s infinite;
}

@keyframes yourAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.originConnectionType {
  @include font-50();
  color: var(--grey-900);
  display: flex;
  align-items: center;
  margin: var(--spacer-8) 0;
  justify-content: space-between;
  span {
    &:nth-child(1) {
      color: var(--grey-500);
      font-size: 12px;
    }
  }
}

.originConnectionState {
  color: var(--success);
}
