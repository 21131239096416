@import "../../styles/container";
@import "../../styles/font-styles";

.container {
  width: 100%;
  height: 92%;
  overflow: hidden;
  padding: var(--spacer-16) var(--spacer-24) var(--spacer-8);
  overflow: hidden;

  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}

.header {
  z-index: 5;
  background: #ffffff;
  border-bottom: 1px solid #efefef;
  width: 100%;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionBlock {
  display: flex;
  align-items: center;
}

.title {
  align-items: center;
  gap: var(--spacer-8);
  display: flex;

  span {
    @include font-100();
    font-size: 20px;
    line-height: 24px;
  }
}

.error {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font-100();
}