@import "../../styles/font-styles";
@import "../../styles/container";

.customNode {
  border: 1px solid var(--grey-200);
  border-radius: var(--radius-4);
  background: var(--white);
  width: 120px;
  @include mainShadow();
  position: relative;

  &._merge,
  &._process {
    width: 175px;
  }
}

.customNodeSelected {
  border: 1px solid var(--accent-first);
}

.customNodeTitle {
  background: #fafafa;
  border-radius: var(--radius-4) var(--radius-4) 0 0;
  display: flex;
  align-items: center;
  gap: var(--spacer-8);
  padding: 14px 10px;
  color: var(--black);

  span {
    @include font-25();
    @include short-text(auto);
  }

  &._mergeTitle {
    background: var(--white);
    border-bottom: 1px solid var(--grey-400);
  }

  :global {
    .ant-tag {
      background-color: transparent;
      border: 1px solid var(--accent-first);
      color: var(--black);
      border-radius: 12px;
      padding: 2px 6px;
    }
  }
}

.customNodeBody {
  padding: 8px;
}

.customNodeField {
  display: flex;
  align-items: center;
  gap: var(--spacer-8);
  position: relative;
  padding: 10px;
  color: #8a8a8a;
  border-bottom: 1px solid var(--grey-200);
  background: var(--white);
  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
}

.customNodeName {
  @include short-text(100%);
}

.customNodeClose {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--spacer-4);
  cursor: pointer;
}

.emptyAttr {
  background-color: var(--white);
  gap: var(--spacer-8);
  flex-direction: column;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transformIcon {
  --app-icon-color: var(--accent-first);
}
