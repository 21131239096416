@import "../../../../styles/font-styles";

.dangerText {
  color: var(--danger);
  font-size: 12px;
  padding: 0 4px 4px;
  width: 100%;
}

.drawerAction {
  height: calc(100% - 148px);
  width: calc(100% - 48px);
  padding: 1px;
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  justify-content: center;
  align-items: center;
  &Error {
    display: flex;
    color: var(--danger-200);
    flex-direction: column;
    gap: var(--spacer-8);
    align-items: center;
    &Icon {
      transform: rotate(180deg);
      --app-icon-color: var(--danger-200);
    }
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 300px;
  min-height: 450px;
}

.column {
  padding: var(--spacer-16);

  &._divider {
    border-left: 1px solid #d9d9d9;
  }
}

.entitiesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacer-16);
  align-items: center;
  margin-bottom: var(--spacer-16);
}

.entityWrapper {
  border: 1px solid var(--grey-400);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-8);
  padding: var(--spacer-8);

  --app-input-wrapper-height: 56px;
}

.connectIcon {
  transform: rotateZ(90deg);
  width: 24px;
  height: 24px;
}

.preview {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  padding: 10px 40px 10px 10px;
}

.previewAttr {
  display: flex;
  align-items: center;
  gap: 4px;
  overflow-x: hidden;

  font-size: 12px;
  line-height: 14px;
}
.previewConnectionIcon {
  transform: rotateZ(90deg);
  width: 16px;
  height: 16px;
  --app-icon-color: var(--accent-first);
}

.previewMerge {
  display: flex;
  align-items: center;

  padding: 0 10px;
  border-left: 1px solid #f2f2f2;
  height: 40px;

  position: absolute;
  right: 0;
}

.errorType {
  margin-bottom: var(--spacer-16);
}

.mergeOption {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 6px 0;
}

.mergeTypeWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  grid-template-rows: 1fr 1fr;
}

.mergeType {
  align-items: center;
  border: 1px solid #e8e0e0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 86px;
  justify-content: center;
  line-height: 24px;

  &Active {
    background-color: rgba(0, 87, 255, 0.1);
    border: 1px solid var(--accent-first);
  }

  svg {
    height: 32px;
    width: 32px;
  }
}

.fromSelect {
  align-items: center;
  background: #f2f2f2;
  border-radius: 4px;
  color: var(--accent-first);
  display: flex;
  height: 180px;
  justify-content: center;
}

.rightJoin {
  --app-icon-color: var(--accent-first);
  align-items: center;
  border-radius: 4px;
  color: #737373;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 300;
  justify-content: center;
  line-height: 16px;
}

.rightJoin + .removeButton {
  margin-left: 8px;
}

.contentWrapper {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;

  .content {
    display: flex;
    height: 100%;
  }

  .contentLeft {
    padding: 16px 16px 16px 0;
    border-right: 1px solid #d9d9d9;
    width: 100%;
  }

  .contentRight {
    padding: 16px 0 16px 16px;
    width: 100%;
  }

  .title {
    color: #6a6a6a;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 32px;
  }

  .indexWrapper {
    margin-bottom: 16px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;

    .index {
      border-right: 1px solid #e8e0e0;
      padding: 16px;
      width: 100%;
    }

    .removeButtonWrapper {
      width: 52px;
      height: 148px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .indexInput {
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;

      input {
        border: none;
        width: 100%;
        padding: 12px 16px;
        font-size: 16px;
      }
      input::placeholder {
        color: #bfbfbf;
      }
      .inputWrapper {
        min-height: unset;
      }
    }

    :global {
      .ant-select {
        width: 100%;
      }
    }
  }

  .attributeWrapper {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 8px;
    margin-right: 16px;
  }

  .addButton {
    color: var(--accent-first);
    border: 1px dashed var(--accent-first);
    --app-icon-color: var(--accent-first);
    width: 100%;
  }

  .addLeftButton {
    height: 100%;
  }

  .removeButton {
    --app-icon-color: #ff2e00;
    border: none !important;
    border-radius: 4px;
    box-shadow: none;
    background: var(--white);
    cursor: pointer;
    padding: 4px 8px;
    width: 40px;
    height: 40px;

    &:hover {
      background: rgba(255, 46, 0, 0.1);
    }

    svg {
      margin-top: 3px;
    }
  }

  .groupGroup {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
    margin: 16px 0 24px;

    .groupGroupItemBorder {
      border-left: 1px solid #e5e5e5;
      margin-bottom: 25px !important;

      &:before {
        content: "";
        background: #e5e5e5;
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 10px;
      }

      &:after {
        content: "";
        background: #e5e5e5;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 10px;
      }
    }

    .groupGroupItem {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 14px;

      .groupList {
        width: 100%;

        .groupItem {
          display: flex;
          justify-content: space-between;
          align-items: center;

          button {
            --app-icon-color: #ff2e00;
            border: none !important;
            border-radius: 4px;
            box-shadow: none;
            background: var(--white);
            cursor: pointer;
            padding: 4px 8px;
            width: 40px;
            height: 40px;

            &:hover {
              background: rgba(255, 46, 0, 0.1);
            }
          }

          .groupItemSettings {
            display: flex;
            align-items: start;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

.textInput {
  --app-icon-color: var(--accent-first);
  align-items: center;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  line-height: 24px;
  padding: 0 11px;

  input {
    border: none;
    width: 100%;
    margin-left: 8px;
    padding: 12px 0;
  }
  input::placeholder {
    color: #bfbfbf;
  }
  input:disabled {
    background-color: transparent;
  }
  .inputWrapper {
    min-height: unset;
  }
}

.inputError {
  color: var(--danger);
  margin: 8px;
}

.header {
  margin: 0 -16px 0;
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;
}

.footer {
  background: #fff;
  border-top: 1px solid #d9d9d9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin: 0 -16px -16px;
  padding: 24px;
  position: relative;
  z-index: 1001;

  button {
    border-radius: 4px;
    border: 1px solid var(--accent-first);
    cursor: pointer;
    font-size: 14px;
    padding: 13px 18px;
  }

  .applyOperatorButton {
    padding: 24px 18px;
  }
}

.addEntityOkButton {
  margin-top: 32px;
}

.groupSelectOption {
  @include font-100();
}

.groupBrace {
  margin-right: 16px;
  margin-left: -1px;
  padding: 8px 0 8px;
  border: 1px solid white;

  :global {
    .ant-select {
      border: 1px solid #9b9b9b;
      border-radius: 4px;
      width: 80px;
    }

    .ant-select-selector {
      min-height: auto;
    }
  }
}

:global {
  .groupSelect {
    width: 100%;
    min-width: 200px;

    .ant-select {
      width: 100%;
    }

    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        background: rgba(0, 87, 255, 0.2);
      }
    }

    .ant-select-multiple {
      .ant-select-selector {
        padding: 0 16px;
      }
    }
  }

  .group-modal {
    --app-input-wrapper-height: auto;

    .ant-tag {
      background-color: transparent;
      border: 2px solid var(--accent-first);
      color: var(--black);
      border-radius: 12px;
      padding: 3px 6px;
      margin-left: 8px;
      font-weight: 400;
    }

    .ant-modal-body {
      padding: 16px;
    }

    .ant-select-selector {
      border: 1px solid #f2f2f2 !important;
      color: var(--grey-900);
      border-radius: 4px;
      font-size: 16px;
      align-items: center !important;
    }

    .ant-select-selection-item {
      font-weight: 300;
      line-height: 24px;
    }

    .ant-select-selection-search {
      align-items: center;
      display: flex;
    }

    .select-from-entity {
      line-height: 18px;

      .ant-select-selector {
        border-radius: 4px;

        .ant-select-selection-item {
          line-height: 24px;
        }
      }
    }

    .select-from-entity-collapsed {
      .ant-select-selector {
        min-height: 30px;
      }
    }
  }

  .select-from-entity-option-item {
    color: var(--grey-900);
    font-size: 16px;
    max-width: 170px;
  }

  .select-from-entity-option-label {
    font-size: 12px;
    color: var(--accent-first);
  }

  .select-from-entity-option-type {
    font-size: 12px;
    color: #737373;
  }

  .select-from-entity-option {
    .ant-select-item,
    .ant-select-item-option,
    .ant-select-item-option-content {
      line-height: 18px;
      font-weight: 300;
    }
  }

  .group-form-add-entity {
    .ant-modal-close-x {
      height: 70px;
      line-height: 70px;
      width: 70px;
      font-size: 24px;
      color: #979797;
    }

    .ant-modal-header {
      padding: 24px;
    }

    .ant-modal-title {
      font-size: 24px;
    }

    .ant-select {
      border-radius: 4px;
      width: 100%;
    }

    .ant-select-selector {
      align-items: center;
      border: 1px solid #f2f2f2 !important;
      border-radius: 4px;
      color: var(--grey-900);
      font-size: 16px;
      width: 100%;
    }

    .ant-select-selection-search {
      align-items: center;
      display: flex;
    }

    .ant-select-selection-item {
      font-weight: 300;
      align-items: center;
      display: flex;
    }

    .select-from-entity {
      line-height: 18px;

      .ant-select-selection-item {
        line-height: 16px;
      }
    }
  }
}
