@import "../../../styles/font-styles";

.container {
  height: calc(100% - 89px);
  margin: 0 var(--spacer-24);
  width: unset;
  position: relative;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.asideContainer {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 100%;
  width: 280px;
  overflow: auto;
  z-index: 10;
  padding-bottom: 95px;
}

.aside {
  width: 280px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  gap: var(--spacer-16);
}

.asideTitle {
  @include font-50();
  font-weight: 500;
  color: #6a6a6a;
}

.asideAddButton {
  width: 90% !important;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputEntity {
  --app-input-wrapper-height: auto;
  --app-input-inner-wrapper-height: auto;
}
.buttonEntity {
  height: 50px;
}

.footer {
  width: calc(100% + 48px);
  background: var(--white);
  z-index: 100;
  position: absolute;
  padding: 24px 40px;
  bottom: 0;
  left: -24px;
  border-top: 1px solid #efefef;
}

.footerTable {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #efefef;
}

.footerButtons {
  display: flex;
  justify-content: space-between;
  background-color: var(--white);
  position: relative;
  z-index: 100;
}

.generateSql {
  position: absolute;
  top: 16px;
  right: 24px;

  z-index: 10;
}

.asideCollapse {
  &Panel {
    background: var(--white);
    border: 1px solid var(--grey-300);
    box-shadow: 0px 4.2px 16.8px rgba(112, 112, 112, 0.08);
    border-radius: var(--radius-8);
    margin-bottom: var(--spacer-16);
    .ant-collapse-content-box {
      padding: 0;
    }
  }
}
