@import "../../styles/font-styles";

.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.homeRender {
  min-height: inherit;
}

.emptyState {
  position: absolute;
  background: transparent;
  z-index: 10;
  left: 0;
  top: 0;
  min-height: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacer-24);
}

.searchContainer {
  display: flex;
  gap: var(--spacer-16);
  padding: var(--spacer-16);
  align-items: center;
  z-index: 20;
}

.emptyContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  h2 {
    @include font-600();
    margin-bottom: var(--spacer-16);
  }

  p {
    @include font-100();
    margin-bottom: var(--spacer-32);
  }
}

.notSelected {
  position: absolute;
  p {
    max-width: 240px;
    text-align: center;
  }
}

.soursesList {
  overflow: scroll;
}

.rightBarTitle {
  background-color: #0057ff0d;
  border-bottom: 1px solid #0057ff;
  padding: var(--spacer-16);
  font-size: 18px;
  display: flex;
  gap: var(--spacer-8);
  align-items: center;
  @include font-200();
}
.searchInput {
  flex: 1;
  --app-input-wrapper-height: 50px;
}

.rightBarButton {
  display: flex;
  justify-content: center;
  height: 100%;
}

.searchListWrapper {
  position: relative;
  height: 100%;
  &::after {
    display: flex;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--spacer-16);
    z-index: 10;
    background: var(--white);
  }
}

.searchList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: repeat(2, auto) repeat(3, 1fr) repeat(2, auto);
  padding: 0 var(--spacer-16) var(--spacer-16) var(--spacer-16);
  grid-gap: var(--spacer-16);
  height: calc(100% - 16px);
  margin-bottom: var(--spacer-32);
  width: 100%;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rulesList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-template-rows: max-content;
  padding: 0 var(--spacer-16) var(--spacer-16) var(--spacer-16);
  grid-gap: var(--spacer-16);
  height: 100%;
  margin-bottom: var(--spacer-32);
  width: 100%;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.searchEmpty {
  @include font-100();
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  margin-top: var(--spacer-32);
  text-align: center;
}

.buttonContainer {
  margin-top: var(--spacer-8);
  padding: var(--spacer-8);
}
.connectionsContainer {
  overflow-y: scroll;
  padding-top: var(--spacer-8);
  padding-bottom: var(--spacer-16);
}
