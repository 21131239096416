@import "./font-styles";

.ant-collapse-header-text {
  @include short-text(70%);
}

.ant-collapse-expand-icon {
  display: flex;
  align-items: center;
}

.ant-collapse-header {
  align-items: center !important;
}

.ant-tooltip-inner {
  color: var(--grey-900);
  @include font-50();
}

.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  border-top: 1px solid #d9d9d9 !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: unset !important;
}

.ant-dropdown-menu-item {
  padding: 12px;
}

.ant-select-item-option-selected > .ant-select-item-option-content {
  @include font-100();
}

.ant-switch {
  height: 30px;
  border-radius: 7px;
  line-height: 30px;
  padding: 0 6px;
}

.ant-switch-inner {
  margin: 0 8px 0 32px;
  @include font-50();
  color: var(--white);
}

.ant-switch-checked .ant-switch-inner {
  margin: 0 32px 0 8px;
}

.ant-switch-handle {
  width: 22px;
  height: 22px;
  top: 4px;
  left: 6px;

  &::before {
    border-radius: 6px;
  }
}

.ant-switch-checked {
  background: var(--accent-first);
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 9px);
}

.ant-tag {
  border: 1px solid transparent;
  border-radius: 4px;
  color: #3c3c3c;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  padding: 6px;
}

.ant-modal-header {
  border-bottom: 1px solid #d9d9d9;
  border-radius: 10px 10px 0 0;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-drawer-content,
.ant-drawer-body {
  overflow: unset;
}

.ant-select-selector {
  min-height: 50px;
}

.ant-steps-item-title {
  @include font-50();
  line-height: var(--spacer-32);
}

.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--accent-first);
  border-color: var(--accent-first);
}
.ant-checkbox {
  margin: auto 0;
}

.ant-checkbox + span {
  position: relative;
  top: 2px;
}
