@import "../../../styles/font-styles";

.wrapper {
  border: 1px solid #ec1d1d;
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 4px;
}
.icon {
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
}
.content {
  flex: 1;
  @include font-50();
}
.action {
  display: inline-flex;
  gap: var(--spacer-4);
}
