.leftBlock {
  padding: 16px 8px 16px 16px;
  border-right: 1px solid #d9d9d9;
  height: 65vh;
  overflow: auto;
}

.rightBlock {
  padding: 16px 16px 16px 8px;
}

.rightBlockHeader {
  color: #6a6a6a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 16px;
}

.tableName {
  display: flex;
  justify-content: flex-start;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #F2F2F2;

  .tableNameElement {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 300;
  }
}

.dropContainer {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-top: 16px;
}

.dropBlockCommon {
  padding: 8px;
  width: 100%;
}

.dropContainerError {
  color: #FF2E00;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  margin-top: 8px;
}

.dropField {
  border-radius: 4px;
  border: 1px dashed #d9d9d9;
  height: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6a6a6a;
  text-align: center;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
}

.deleteIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 100px;
  cursor: pointer;
}

.dropContainerSum {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.result {
  border-radius: 4px;
}

.addButton {
  --app-icon-color: var(--accent-first);
  background-color: #fff;
  border-radius: 4px;
  border: 1px dashed var(--accent-first);
  color: #0057ff;
  cursor: pointer;
  font-size: 18px;
  padding: 8px 0;
  text-align: center;
  width: 100%;
  margin: 16px 0;
}

.addButtonDisabled {
  --app-icon-color: #d3d1d1;
  background-color: #fff;
  border-radius: 4px;
  border: 1px dashed #d9d9d9;
  color: #d3d1d1;
  cursor: not-allowed;
  font-size: 18px;
  padding: 8px 0;
  text-align: center;
  width: 100%;
}

.itemContainer {
  width: 270px;
  min-height: 80px;
  border-radius: 4px;
  border: 1px solid #e8e0e0;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemContainerLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}

.removeIcon {
  cursor: pointer;
}

.attributeTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.attributeType,
.entityTitle {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  color: #737373;

  span {
    color: #0057ff;
  }
}

.emptyAttribute {
  color: #737373;
}

.dangerText {
  color: var(--danger);
  font-size: 12px;
  padding: 0 4px 4px;
  width: 100%;
}

.drawerAction {
  height: calc(100% - 148px);
  width: calc(100% - 48px);
  padding: 1px;
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  justify-content: center;
  align-items: center;
  &Error {
    display: flex;
    color: var(--danger-200);
    flex-direction: column;
    gap: var(--spacer-8);
    align-items: center;
    &Icon {
      transform: rotate(180deg);
      --app-icon-color: var(--danger-200);
    }
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 300px;
  min-height: 450px;
}

.column {
  padding: var(--spacer-16);

  &._divider {
    border-left: 1px solid #d9d9d9;
  }
}

.textInput {
  --app-icon-color: var(--accent-first);
  align-items: center;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  line-height: 24px;
  padding: 0 11px;

  input {
    border: none;
    width: 100%;
    margin-left: 8px;
    padding: 12px 0;
  }
  .inputWrapper {
    min-height: unset;
  }
}

.inputError {
  color: var(--danger);
  margin: 8px;
}

.addButton {
  --app-icon-color: var(--accent-first);
  background-color: #fff;
  border-radius: 4px;
  border: 1px dashed var(--accent-first);
  color: #0057ff;
  cursor: pointer;
  font-size: 18px;
  padding: 8px 0;
  text-align: center;
  width: 100%;
}

.addButtonDisabled {
  --app-icon-color: #d3d1d1;
  background-color: #fff;
  border-radius: 4px;
  border: 1px dashed #d9d9d9;
  color: #d3d1d1;
  cursor: not-allowed;
  font-size: 18px;
  padding: 8px 0;
  text-align: center;
  width: 100%;
}

.footer {
  background: #fff;
  border-top: 1px solid #d9d9d9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  position: relative;
  z-index: 1001;

  .footerLeftBtns {
    display: flex;
    gap: 16px;
  }

  button {
    border-radius: 4px;
    border: 1px solid var(--accent-first);
    cursor: pointer;
    font-size: 12px;
    padding: 13px 18px;
  }

  .applyOperatorButton {
    padding: 24px 18px;
  }

  .footerTitle {
    color: #9B9B9B;
    font-size: 16px;
    font-weight: 300;
  }
}

.searchInput {
  margin-bottom: var(--spacer-16);

  input {
    padding-right: var(--spacer-16);
    height: auto !important;
    width: 100% !important;
    border: 1px solid #e8e0e0;
  }
}

.infoContainer {
  border-radius: 4px;
  border: 1px solid #E8E0E0;
  font-size: 16px;
  font-weight: 300;
}

.infoContainerTitle {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.infoContainerBody {
  color: #3C3C3C;
  border-top: 1px solid #E8E0E0;
  padding: 16px;
  font-size: 12px;

  span {
    color: #0057FF;
  }
}

:global {
  .join-modal {
    --app-input-wrapper-height: auto;

    .ant-tag {
      background-color: transparent;
      border: 2px solid var(--accent-first);
      color: var(--black);
      border-radius: 12px;
      padding: 3px 6px;
      margin-left: 8px;
      font-weight: 400;
    }

    .ant-input {
      height: 56px;
      width: 260px;
    }

    @media (max-width: 1100px) {
      .ant-input {
        width: 170px;
      }
    }

    @media (max-width: 1300px) {
      .ant-input {
        width: 200px;
      }
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-select-selector {
      border: 1px solid #f2f2f2 !important;
      border-radius: 4px;
      color: var(--grey-900);
      font-size: 16px;
    }

    .ant-select-selection-item {
      font-weight: 300;
      line-height: 24px;
    }

    .ant-select-selection-search {
      align-items: center;
      display: flex;
    }

    .select-from-entity {
      line-height: 18px;

      .ant-select-selector {
        border-radius: 4px;
        min-height: 46px;

        .ant-select-selection-placeholder {
          color: #6a6a6a;
        }

        .ant-select-selection-item {
          line-height: 24px;
        }
      }
    }

    .select-from-entity-collapsed {
      .ant-select-selector {
        min-height: 30px;
      }
    }

    .ant-collapse-content-box {
      padding-left: 14px;
      overflow: auto;
      max-height: 500px;
    }
  }

  .entity {
    display: flex;
    justify-content: space-between;

    .entity-storage-name {
      font-size: 12px;
      color: #737373;
      margin-left: 8px;
    }
  }

  .select-from-entity-option-item {
    color: var(--grey-900);
    font-size: 16px;
  }

  .select-from-entity-option-label {
    font-size: 12px;
    color: var(--accent-first);
  }

  .select-from-entity-option-type {
    font-size: 12px;
    color: #737373;
  }

  .select-from-entity-option {
    .ant-select-item,
    .ant-select-item-option,
    .ant-select-item-option-content {
      line-height: 18px;
      font-weight: 300;
    }
  }

  .merge-form-add-entity {
    .ant-modal-close-x {
      height: 70px;
      line-height: 70px;
      width: 70px;
      font-size: 24px;
      color: #979797;
    }

    .ant-modal-header {
      padding: 24px;
    }

    .ant-modal-title {
      font-size: 24px;
    }

    .ant-select {
      border-radius: 4px;
      width: 100%;
    }

    .ant-select-selector {
      align-items: center;
      border: 1px solid #f2f2f2 !important;
      border-radius: 4px;
      color: var(--grey-900);
      font-size: 16px;
      width: 100%;
    }

    .ant-select-selection-search {
      align-items: center;
      display: flex;
    }

    .ant-select-selection-item {
      font-weight: 300;
      align-items: center;
      display: flex;
    }

    .ant-select-selection-placeholder {
      color: #6a6a6a;
    }

    .select-from-entity {
      line-height: 18px;

      .ant-select-selection-item {
        height: 50px;
        line-height: 16px;
      }
    }
  }

  .merge-sql {
    .ant-drawer-mask {
      border-radius: 10px;
    }

    .ant-drawer-header {
      padding: 8px 0;
    }

    .ant-drawer-title {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      .ant-space {
        padding: 24px;
        cursor: pointer;
      }

      div {
        height: 16px;
        --app-icon-color: var(--accent-first);
      }
    }

    .ant-drawer-content-wrapper,
    .ant-drawer-content {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .ant-drawer-body {
      margin-bottom: 100px;
      position: relative;
      overflow: auto;

      pre,
      & > div {
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        overflow-y: auto;
        padding: 16px !important;
      }
    }
  }
}

@media (max-width: 1250px) {
  .itemContainer {
    width: 200px;
  }
}