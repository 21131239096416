.dangerText {
  color: var(--danger);
  font-size: 12px;
  padding: 0 4px 4px;
  width: 100%;
}

.drawerAction {
  height: calc(100% - 148px);
  width: calc(100% - 48px);
  padding: 1px;
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  justify-content: center;
  align-items: center;
  &Error {
    display: flex;
    color: var(--danger-200);
    flex-direction: column;
    gap: var(--spacer-8);
    align-items: center;
    &Icon {
      transform: rotate(180deg);
      --app-icon-color: var(--danger-200);
    }
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 300px;
  min-height: 450px;
}

.column {
  padding: var(--spacer-16);

  &._divider {
    border-left: 1px solid #d9d9d9;
  }
}

.entitiesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacer-16);
  align-items: center;
  margin-bottom: var(--spacer-16);
}

.entityWrapper {
  border: 1px solid var(--grey-400);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-8);
  padding: var(--spacer-8);

  --app-input-wrapper-height: 56px;
}

.connectIcon {
  transform: rotateZ(90deg);
  width: 24px;
  height: 24px;
}

.preview {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  padding: 10px 40px 10px 10px;
}

.previewAttr {
  display: flex;
  align-items: center;
  gap: 4px;
  overflow-x: hidden;

  font-size: 12px;
  line-height: 14px;
}
.previewConnectionIcon {
  transform: rotateZ(90deg);
  width: 16px;
  height: 16px;
  --app-icon-color: var(--accent-first);
}

.previewMerge {
  display: flex;
  align-items: center;

  padding: 0 10px;
  border-left: 1px solid #f2f2f2;
  height: 40px;

  position: absolute;
  right: 0;
}

.errorType {
  margin-bottom: var(--spacer-16);
}

.mergeOption {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 6px 0;
}

.mergeTypeWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  grid-template-rows: 1fr 1fr;
}

.chooseBlocked {
  cursor: default !important;
}

.mergeType {
  align-items: center;
  border: 1px solid #e8e0e0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 86px;
  justify-content: center;
  line-height: 24px;

  &Active {
    background-color: rgba(0, 87, 255, 0.1);
    border: 1px solid var(--accent-first);
  }


  svg {
    height: 32px;
    width: 32px;
  }
}

.stepper {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 16px;

  .step {
    .subheading {
      background-color: #f2f2f2;
      border-radius: 4px;
      color: var(--grey-900);
      font-size: 18px;
      margin-right: 10px;
      padding: 6px 6px;
    }

    .description {
      color: #6a6a6a;
      font-size: 12px;
      width: 160px;
    }

    .heading {
      align-items: center;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      margin-bottom: 16px;

      .text {
        align-items: center;
        display: flex;
        height: 32px;
      }

      .icon {
        --app-icon-color: #00b21d;
        border: 1px solid #00b21d;
        border-radius: 24px;
        display: flex;
        padding: 3px;
      }
    }

    .entityName {
      color: var(--accent-first);
      font-size: 16px;
      line-height: 24px;
    }

    .fromEntitySelect {
      align-items: flex-start;
      background-color: #fff;
      border-radius: 4px;
      color: #3c3c3c;
      display: flex;
      font-size: 16px;
      font-size: 16px;
      line-height: 18px;
      padding: 2px 2px 2px 8px;

      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%230057ff%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 0.65em auto, 100%;

      &:focus {
        border: 1px solid var(--accent-first);
        border-radius: 4px;
        outline: none;
      }
    }
  }
}

.fromSelect {
  align-items: center;
  background: #f2f2f2;
  border-radius: 4px;
  color: var(--accent-first);
  display: flex;
  height: 180px;
  justify-content: center;
}

.rightJoin {
  --app-icon-color: var(--accent-first);
  align-items: center;
  border-radius: 4px;
  color: #737373;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 300;
  height: 180px;
  justify-content: center;
  line-height: 16px;

  button {
    font-size: 18px;
    border: none !important;
    box-shadow: none;
  }

  .entityAvailable {
    color: var(--accent-first);
  }

  &Empty {
    border: 1px dashed var(--accent-first);
  }

  &Full {
    border: none;
    background-color: #f2f2f2;
    padding: 16px;
  }

  &Panel {
    &Full {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      --app-icon-color: #3c3c3c;
    }

    button {
      cursor: pointer;
      padding: 2px 4px;

      &:hover {
        background-color: rgba(230, 230, 230, 1);
        border-radius: 4px;
      }
    }
  }
}

.content {
  height: 50vh;
  overflow-y: auto;

  .join {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    margin: 16px 0;

    .title {
      color: var(--grey-900);
      border-bottom: 1px solid #d9d9d9;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        --app-icon-color: #ff2e00;
        border: none !important;
        border-radius: 4px;
        box-shadow: none;
        background: var(--white);
        cursor: pointer;
        margin: -8px;
        padding: 4px 8px;

        &:hover {
          background: rgba(255, 46, 0, 0.1);
        }
      }
    }
  }

  .noEntityText {
    align-items: center;
    color: #6a6a6a;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    height: 100%;
    justify-content: center;
    line-height: 26px;
    text-align: center;

    .subheading {
      color: var(--grey-900);
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
}

.textInput {
  --app-icon-color: var(--accent-first);
  align-items: center;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  line-height: 24px;
  padding: 0 11px;

  input {
    border: none;
    width: 100%;
    margin-left: 8px;
    padding: 12px 0;
  }
  .inputWrapper {
    min-height: unset;
  }
}

.inputError {
  color: var(--danger);
  margin: 8px;
}

.header {
  margin: 0 -16px 32px;
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;
}

.addButton {
  --app-icon-color: var(--accent-first);
  background-color: #fff;
  border-radius: 4px;
  border: 1px dashed var(--accent-first);
  color: #0057ff;
  cursor: pointer;
  font-size: 18px;
  padding: 8px 0;
  text-align: center;
  width: 100%;
}

.addButtonDisabled {
  --app-icon-color: #d3d1d1;
  background-color: #fff;
  border-radius: 4px;
  border: 1px dashed #d9d9d9;
  color: #d3d1d1;
  cursor: not-allowed;
  font-size: 18px;
  padding: 8px 0;
  text-align: center;
  width: 100%;
}

.footer {
  background: #fff;
  border-top: 1px solid #d9d9d9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin: 16px -16px -16px;
  padding: 24px;
  position: relative;
  z-index: 1001;

  button {
    border-radius: 4px;
    border: 1px solid var(--accent-first);
    cursor: pointer;
    font-size: 14px;
    padding: 13px 18px;
  }

  .applyOperatorButton {
    padding: 24px 18px;
  }
}

.addEntityOkButton {
  margin-top: 32px;
}

.menuIcon {
  align-items: flex-end;
  display: flex;
  gap: 4px;
  width: 140px;
}

:global {
  .merge-modal {
    --app-input-wrapper-height: auto;

    .ant-tag {
      background-color: transparent;
      border: 2px solid var(--accent-first);
      color: var(--black);
      border-radius: 12px;
      padding: 3px 6px;
      margin-left: 8px;
      font-weight: 400;
    }

    .ant-modal-body {
      padding: 16px;
    }

    .ant-select-selector {
      border: 1px solid #f2f2f2 !important;
      border-radius: 4px;
      color: var(--grey-900);
      font-size: 16px;
    }

    .ant-select-selection-item {
      font-weight: 300;
      line-height: 24px;
    }

    .ant-select-selection-search {
      align-items: center;
      display: flex;
    }

    .select-from-entity {
      line-height: 18px;

      .ant-select-selector {
        border-radius: 4px;
        min-height: 46px;

        .ant-select-selection-placeholder {
          color: #6a6a6a;
        }

        .ant-select-selection-item {
          line-height: 24px;
        }
      }
    }

    .select-from-entity-collapsed {
      .ant-select-selector {
        min-height: 30px;
      }
    }
  }

  .entity {
    display: flex;
    justify-content: space-between;
  
    .entity-storage-name {
      font-size: 12px;
      color:#737373;
      margin-left: 8px;
    }
  }

  .select-from-entity-option-item {
    color: var(--grey-900);
    font-size: 16px;
  }

  .select-from-entity-option-label {
    font-size: 12px;
    color: var(--accent-first);
  }

  .select-from-entity-option-type {
    font-size: 12px;
    color: #737373;
  }

  .select-from-entity-option {
    .ant-select-item,
    .ant-select-item-option,
    .ant-select-item-option-content {
      line-height: 18px;
      font-weight: 300;
    }
  }

  .merge-form-add-entity {
    .ant-modal-close-x {
      height: 70px;
      line-height: 70px;
      width: 70px;
      font-size: 24px;
      color: #979797;
    }

    .ant-modal-header {
      padding: 24px;
    }

    .ant-modal-title {
      font-size: 24px;
    }

    .ant-select {
      border-radius: 4px;
      width: 100%;
    }

    .ant-select-selector {
      align-items: center;
      border: 1px solid #f2f2f2 !important;
      border-radius: 4px;
      color: var(--grey-900);
      font-size: 16px;
      width: 100%;
    }

    .ant-select-selection-search {
      align-items: center;
      display: flex;
    }

    .ant-select-selection-item {
      font-weight: 300;
      align-items: center;
      display: flex;
    }

    .ant-select-selection-placeholder {
      color: #6a6a6a;
    }

    .select-from-entity {
      line-height: 18px;

      .ant-select-selection-item {
        height: 50px;
        line-height: 16px;
      }
    }
  }

  .merge-sql {
    .ant-drawer-mask {
      border-radius: 10px;
    }

    .ant-drawer-header {
      padding: 8px 0;
    }

    .ant-drawer-title {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      .ant-space {
        padding: 24px;
        cursor: pointer;
      }

      div {
        height: 16px;
        --app-icon-color: var(--accent-first);
      }
    }

    .ant-drawer-content-wrapper,
    .ant-drawer-content {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .ant-drawer-body {
      margin-bottom: 100px;
      position: relative;
      overflow: auto;

      pre,
      & > div {
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        overflow-y: auto;
        padding: 16px !important;
      }
    }
  }
}
