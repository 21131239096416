@import "../../../styles/font-styles";

.modalTitle {
  padding: 8px 0;
  @include font-300();
}

.modalContainer {
  padding: var(--spacer-32) var(--spacer-24) var(--spacer-24);
}

.content {
  margin-bottom: var(--spacer-32);
}

.modalFooter {
  display: flex;
  gap: var(--spacer-8)
}
