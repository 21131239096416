@import "../../styles/font-styles";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
}

.emptyState {
  position: absolute;
  background: transparent;
  z-index: 10;
  left: 0;
  top: 0;
  min-height: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacer-24);
}

.searchContainer {
  display: flex;
  gap: var(--spacer-16);
  padding: var(--spacer-16);
  align-items: center;
}

.searchInput {
  flex: 1;
  --app-input-wrapper-height: 50px;
}

.rightBarButton {
  display: flex;
  justify-content: center;
  height: 100%;
}

.searchEmpty {
  @include font-100();
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  margin-top: var(--spacer-32);
  text-align: center;
}

.emptyContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  h2 {
    @include font-600();
    margin-bottom: var(--spacer-16);
  }

  p {
    @include font-100();
    margin-bottom: var(--spacer-32);
  }
}

.notSelected {
  position: absolute;
  p {
    max-width: 240px;
    text-align: center;
  }
}

.domainListWrapper {
  position: relative;
  height: 100%;
  &::after {
    display: flex;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--spacer-16);
    z-index: 10;
    background: var(--white);
  }
}

.domainList {
  display: grid;
  grid-template-rows: max-content;
  padding: 0 var(--spacer-16) var(--spacer-16) var(--spacer-16);
  grid-gap: var(--spacer-16);
  height: 100%;
  margin-bottom: var(--spacer-32);
  width: 100%;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rowWrapper {
  padding: var(--spacer-8);
  &._tags{
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacer-8);
  }
  &._column {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-8);
  }
}

.tableTitle {
  @include font-50();
  color: var(--grey-500);
}

.sourceTag {
  background: rgba(0, 87, 255, 0.2);
  margin-right: unset;
}

.dateInfo {
  display: flex;
  align-items: center;
  gap: var(--spacer-8);
  &Content {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-4);
    span {
      font-size: 10px;
      line-height: 10px;
      color: #9B9B9B;
    }
  }
}
