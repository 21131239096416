.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.notFoundLogo {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: -28px;
}

.notFoundMain {
  background-image: url("../../images/404.png");
  flex: 3;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleNotFound {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
}

.titleAnother {
  font-size: 16px;
  font-weight: 200;
  margin-bottom: 24px;
}