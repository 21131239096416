@import "styles/fonts";
@import "styles/font-styles";
@import "styles/media";
@import "antd/dist/antd";
@import "styles/patch-antd";
@import "styles/patch-introjs";
@import "styles/spacer-generator";

html,
body {
  font-family: "Rubik", sans-serif;
  padding: 0;
  margin: 0;
  background-color: var(--grey-200) !important;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

input,
a,
button {
  outline: none;
}

button,
span,
p,
a,
input,
select {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3 {
  font-family: "Rubik", sans-serif;
  padding: 0;
  margin: 0;
}

select {
  appearance: none;

  option {
    @include font-100();
  }

  &::-ms-expand {
    display: none;
  }
}

.react-flow__attribution {
  display: none;
}

:root {
  /*colors*/
  --grey-200: rgba(226, 226, 226, 0.17);
  --grey-300: rgba(226, 226, 226, 0.37);
  --grey-400: #bcbcbc;
  --grey-500: #757575;
  --grey-900: #3c3c3c;
  --accent-first: #0057ff;
  --accent-second: rgb(67, 119, 225);
  --accent-second-rgb: 67, 119, 225;
  --danger: #e42020;
  --danger-200: #ff3200;
  --white: #ffffff;
  --black: #000000;
  --success: #6da923;
  --green: #00b21d;

  /*fonts*/
  --font-300: 300;
  --font-500: 500;
  --font-700: 700;

  /*spacers*/
  --spacer-2: 2px;
  --spacer-4: 4px;
  --spacer-8: 8px;
  --spacer-10: 10px;
  --spacer-12: 12px;
  --spacer-16: 16px;
  --spacer-24: 24px;
  --spacer-32: 32px;
  --spacer-40: 40px;
  --spacer-48: 48px;
  --spacer-64: 64px;
  --spacer-80: 80px;

  /*border-radius*/
  --radius-4: 4px;
  --radius-8: 8px;
  --radius-12: 12px;

  /*shadow*/
  --shadow-main: 0px 2px 4px rgba(200, 200, 200, 0.4);
}

.react-flow__node {
  padding: 0 !important;
  border: none !important;
}

.ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
