@import "../../styles/font-styles";
@import "../../styles/media";

.button {
  background: var(--accent-first);
  font-size: 16px;
  line-height: 24px;
  border: 1px solid var(--accent-first);
  padding: 12px 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  transition: ease-in-out 0.2s;
  cursor: pointer;
  border-radius: var(--radius-4);
  color: var(--white);
  &._fill {
    width: 100%;
    justify-content: center;
  }
  &._hug {
    width: fit-content;
  }
  &._small {
    padding: 6px 8px;
    font-size: 12px;
    line-height: 12px;
    height: max-content;
  }
  &._disabled {
    background: var(--grey-200);
    color: var(--grey-400);
    cursor: not-allowed;
    border-color: var(--grey-400);
  }

  &._outline {
    background: var(--white);
    border: 1px solid var(--accent-first);
    color: var(--accent-first);
    &:hover {
      background: rgba(var(--accent-second-rgb), 0.1);
    }
    svg {
      path {
        fill: var(--accent-first);
      }
    }
  }
  &._filled {
    background: var(--accent-first);
    border: 1px solid var(--accent-first);
    color: var(--white);
    &:hover {
      background: rgba(var(--accent-second-rgb), 0.95);
    }
    svg {
      path {
        fill: var(--white);
      }
    }
  }
  &._danger {
    background: var(--danger-200);
    color: var(--white);
    border-color: var(--danger-200);

    &:hover {
      background: rgba(255, 50, 0, 0.75);
    }
    svg {
      path {
        fill: var(--white);
      }
    }
  }
  &._outlineDanger {
    color: var(--danger-200);
    border-color: var(--danger-200);

    &:hover {
      background: var(--danger-200);
      color: var(--white);
    }
    svg {
      path {
        fill: var(--white);
      }
    }
  }
  span {
    display: flex;
    gap: var(--spacer-8);
    align-items: center;
  }
  .ant-btn-lg {
    height: 50px;
  }
}
