@import "../../styles/font-styles";

.mobileGuard {
  padding: var(--spacer-24);
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background: var(--white);
}

@media (max-width: 1000px) {
  .mobileGuard {
    display: flex;
    gap: var(--spacer-24);
    h2 {
      @include font-300();
      text-align: center;
    }
  }
}
