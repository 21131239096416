@import "../../../../styles/font-styles";

.cardContainer {
  width: 213px;

  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4.2px 16.8px rgba(112, 112, 112, 0.08);

  border-radius: 8px;
  padding: var(--spacer-16);
  display: flex;
  flex-direction: column;
  gap: var(--spacer-8);
}

.cardLabel {
  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
  color: #8a8a8a;
}

.drawerHeader {
  padding: var(--spacer-16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey-400);
}
.drawerHeaderButton {
  --app-icon-color: var(--accent-first);
  padding: 8px;
}

.drawerHeaderOutButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: var(--font-300);
  font-size: 16px;
  gap: 12px;
  color: var(--grey-900);
}

.drawerBody {
  position: relative;
  padding: var(--spacer-16);
  overflow-y: scroll;
  height: 72%;
}
.drawerAction {
  height: calc(100% - 16px);
  width: calc(100% - 32px);
  padding: 1px;
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  justify-content: center;
  align-items: center;
  &Error {
    display: flex;
    color: var(--danger-200);
    flex-direction: column;
    gap: var(--spacer-8);
    align-items: center;
    &Icon {
      transform: rotate(180deg);
      --app-icon-color: var(--danger-200);
    }
  }
}
