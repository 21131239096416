@import "../../styles/font-styles";

.inputRegexp {
  background: rgba(179, 179, 179, 0.1);
  border: 1px solid #d3d1d1;
  border-radius: 4px;
}

.storageTag {
  background: rgba(0, 255, 56, 0.2);
  &:nth-child(even) {
    background: rgba(222, 16, 255, 0.2);
  }
}

.processStatusWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.processStatusText {
  @include font-50();
  color: #9b9b9b;
  text-transform: capitalize;

  &._active {
    color: var(--green);
  }
  &._error {
    color: var(--danger-200);
  }
  &._created,
  &._draft {
    color: var(--accent-second);
  }
}

.relative {
  position: relative;
}

.archiveContainer {
  height: calc(100% - 57px);
  width: 100%;
  background: rgba(241, 241, 241, 0.9);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;
}
