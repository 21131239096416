@import "../../styles/font-styles";
@import "../../styles/container";

.customNode {
  border: 1px solid var(--grey-200);
  border-radius: 10px;
  background: #ffffff;
  width: 220px;
  @include mainShadow();
}

.customNodeTitleContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.customNodeSchema {
  @include font-50();
  font-size: 10px;
  line-height: 10px;
  color: #979797;
}

.customNodeTitle {
  height: 60px;
  background: #f2f2f2;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  padding: 4px;
  span {
    @include short-text(80%);
  }
}

.customNodeField {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 12px;
  color: #9b9b9b;
  border-bottom: 1px solid var(--grey-200);
  background: #f4f4f4;
  opacity: 0.4;
}
._synchronization {
  background: var(--white);
  opacity: 1;
}
.customNodeDot {
  background-color: var(--grey-400);
  width: 6px;
  height: 6px;
}

.customNodeName {
  @include short-text(70%);
}
.customNodeAttr {
  text-align: right;
}

.nodeSynchronization {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: rgba(112, 112, 112, 0.1);
  svg {
    margin-bottom: var(--spacer-8);
  }
  p {
    width: 120px;
    @include font-50();
  }
}

.emptyAttr {
  background-color: var(--white);
  gap: var(--spacer-8);
  flex-direction: column;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

