@import "../../styles/container";
@import "../../styles/font-styles";

.header {
  @include baseContainer();
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerLogo {
  height: 40px;
  margin: var(--spacer-16);
  display: flex;
  align-items: center;
  position: relative;
}

.logoAnimated {
  margin-right: -15px;
  margin-left: -15px;
}

.headerLogout,
.headerHow {
  transition: ease-in-out 0.2s;
  &:hover {
    background: var(--grey-200);
  }
}

.headerLogout {
  display: flex;
  align-items: center;
  gap: var(--spacer-8);
  @include font-100();
  cursor: pointer;
  padding: 0 var(--spacer-24);
  height: 100%;
  border-left: 1px solid var(--grey-300);
}

.headerContent {
  display: flex;
  align-items: center;
  height: 100%;
}
.headerHow {
  @include font-100();
  display: flex;
  align-items: center;
  gap: var(--spacer-8);
  cursor: pointer;
  padding: var(--spacer-24);
  --app-icon-color: var(--accent-first);
}
