@import "../../styles/font-styles";

.modalTitle {
  padding: 8px 0;
  @include font-300();
}

.processLayout {
  display: flex;
  min-height: 500px;
  position: relative;
}

.processFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: var(--spacer-16);
}

.processHelp {
  padding: var(--spacer-16);
  max-width: 340px;
}

.stepContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 340px;

  border-right: 1px solid #d9d9d9;
}

.stepItem {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: var(--spacer-16);
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  @include font-400();

  position: relative;
}

.stepItemActive {
  &::after {
    content: "";
    width: 6px;
    height: 40px;
    display: block;

    border-radius: 8px 0px 0px 8px;
    background: #0057ff;

    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  & > .stepKey {
    color: #0057ff;
    border: 1px solid #0057ff;
  }
}

.stepKey {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.stepKeyActive {
  color: #0057ff;
  border: 1px solid #0057ff;
}

.content {
  width: 100%;
  padding: var(--spacer-24);
}

.formWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  :global {
    .ant-select-selection-item {
      border: 1px solid transparent;
      border-radius: 4px;

      @include font-50();
    }
    .ant-select-selection-overflow-item {
      &:nth-child(n) {
        .ant-select-selection-item {
          background: rgba(222, 16, 255, 0.2);
        }
      }
      &:nth-child(2n) {
        .ant-select-selection-item {
          background: rgba(0, 255, 56, 0.2);
        }
      }
      &:nth-child(3n) {
        .ant-select-selection-item {
          background: rgba(255, 0, 15, 0.2);
        }
      }
      &:nth-child(4n) {
        .ant-select-selection-item {
          background: rgba(255, 184, 0, 0.2);
        }
      }
      &:nth-child(5n) {
        .ant-select-selection-item {
          background: rgba(0, 87, 255, 0.2);
        }
      }
    }
  }
}

.formTitle {
  margin-bottom: var(--spacer-16);

  @include font-300();
  color: #6a6a6a;
}

.entityWrapper {
  padding: 8px;
  border-radius: 12px;

  --app-input-wrapper-height: 50px;
  margin-top: 8px;
  &:nth-child(n) {
    background: rgba(222, 16, 255, 0.1);
  }
  &:nth-child(2n) {
    background: rgba(0, 255, 56, 0.1);
  }
  &:nth-child(3n) {
    background: rgba(255, 0, 15, 0.1);
  }
  &:nth-child(4n) {
    background: rgba(255, 184, 0, 0.1);
  }
  &:nth-child(5n) {
    background: rgba(0, 87, 255, 0.1);
  }
}
.entityTitle {
  display: flex;
  align-items: center;

  gap: 8px;
  margin-bottom: 10px;

  @include font-50();
}

.entitiesList {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-16);
  margin-bottom: var(--spacer-24);
}

.collapse {
  background: #ffffff;
  border-radius: 4px;
}

.collapseHeader {
  display: flex;
  gap: 8px;
  align-items: center;
  @include font-50();
}

.collapseEntity {
  display: flex;
  align-items: center;
}

.collapseEntityLine {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: default;
  user-select: none;
}

.collapseEntityLineLeaf {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;

  &:before {
    position: absolute;
    top: 0;
    right: 12px;
    bottom: -4px;
    margin-left: -1px;
    border-right: 1px solid #d9d9d9;
    content: " ";
  }

  &:after {
    position: absolute;
    width: 10px;
    height: 14px;
    border-bottom: 1px solid #d9d9d9;
    content: " ";
  }
}

.collapseEntityLineLast {
  & .collapseEntityLineLeaf {
    &:before {
      top: auto !important;
      bottom: auto !important;
      height: 14px !important;
    }
  }
}

.collapseEntityName {
  display: flex;
  gap: 10px;
  @include font-25();
  padding: 6px;
  border: 1px solid #e8e0e0;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
}

.helpSql {
  margin-bottom: var(--spacer-16);
}

.textRed {
  color: rgba(255, 0, 15, 0.6);
}

.textBlue {
  color: rgba(0, 87, 255, 0.6);
}
