@import "../../styles/font-styles";

.checkBoxWrapper {
  padding: 0 0 12px;
  @include font-100();
}

.checkBoxGroup {
  display: flex;
  &._horizontal {
    flex-direction: row;
  }
  &._vertical {
    flex-direction: column;
  }
}
