@import "../../styles/font-styles";
.onlineAccess {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacer-32);
  z-index: 8000;
  button {
    gap: var(--spacer-8);
    svg {
      --app-icon-color: #ffffff
    }
    &:hover {
      transition: inherit;
      svg {
      --app-icon-color: #40A9FF
      }
    }
  }

  &Title {
    @include font-200();
    text-align: center;
  }
  &Info {
    transform: rotate(180deg);
    --app-icon-color: var(--accent-first)
  }
}
