@import "../../../styles/font-styles";
.fileForm {
  --app-icon-color: var(--accent-first)
}

.fileFormButton {
  margin-top: var(--spacer-24);
}

.inputError {
  color: var(--danger);
  margin-bottom: 32px;
}
