@mixin mainShadow {
  box-shadow: 0px 5px 20px rgba(112, 112, 112, 0.08);
}

@mixin baseContainer() {
  border-radius: var(--radius-12);
  @include mainShadow();
  background: var(--white);
  border: 1px solid var(--grey-200);
}
