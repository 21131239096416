@font-face {
    font-family: 'Rubik';
    src: url('~/src/fonts/Rubik-Regular.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('~/src/fonts/Rubik-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('~/src/fonts/Rubik-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
