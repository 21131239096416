@import "../../styles/font-styles";


.radioCalendar {
  padding: var(--spacer-8);
  border: 1px solid var(--grey-400);
  border-radius: var(--radius-4);
  position: relative;
  margin-bottom: var(--spacer-16);
}

.radioWrapper {
  display: grid;
  width: 100%;
  gap: var(--spacer-4);
  grid-template-columns: repeat(7 , 1fr);
}

.radioGroup {
  width: 100%;
}

.radioButton {
  flex: 1;
  display: flex;
  justify-content: center;
}

.radioCalendarLabel {
  position: absolute;
  z-index: 10;
  left: 16px;
  top: -12px;
  padding: 2px;
  background-color: var(--white);
  @include font-25();
}
