@import "../../styles/container";
@import "../../styles/font-styles";

.processHead {
  z-index: 5;
  background: #ffffff;
  border-bottom: 1px solid #efefef;
  width: 100%;
  border-radius: 10px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacer-24);
  &Container {
    margin-left: var(--spacer-8);
    display: flex;
    flex-direction: column;
    gap: 2px;
    &Label {
      @include font-25();
      color: #9B9B9B;
    }
    &Title {
      margin: unset;
    }
  }

  .leftBlock{
    display: flex;
    align-items: center;
    flex: auto;

    .processBlock {
      display: flex;
      gap: var(--spacer-8);
      align-items: center;
      margin-right: 8px;
    }
  }

  .rigntBlock{
    flex: 1;
    display: flex;
    align-items: center;
    
    .actions {
      border-right: 1px solid #e8e0e0;
    }
  }
}

.originBlock {
  align-items: center;
  gap: var(--spacer-8);
  display: flex;
  @include font-100();
}

.stepsContainer {
  max-width: 700px;
  margin-left: 12px;
}
.processBody {
  padding: var(--spacer-24) var(--spacer-48);
  height: calc(100% - 62px);
}

.processLowCodeBody {
  height: 100%;
}

.formTitleContainer {
  min-width: 500px;
}
.formTitle {
  @include font-100-bold();
  display: flex;
  align-items: center;
  gap: var(--spacer-8);
  .helpIcon {
    --app-icon-color: var(--accent-first);
    cursor: pointer;
  }
  .helpIconContainer {
    display: flex;
  }
}

.formSection {
  display: flex;
}

.formFields {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-16);
  width: 100%;
  padding-right: 180px;
}

.formInner {
  display: flex;
  gap: var(--spacer-16);
}

.formWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--spacer-32);
  :global {
    .ant-select-selection-item {
      border: 1px solid transparent;
      border-radius: 4px;

      @include font-50();
    }
    .ant-select-selection-overflow-item {
      &:nth-child(n) {
        .ant-select-selection-item {
          background: rgba(222, 16, 255, 0.2);
        }
      }
      &:nth-child(2n) {
        .ant-select-selection-item {
          background: rgba(0, 255, 56, 0.2);
        }
      }
      &:nth-child(3n) {
        .ant-select-selection-item {
          background: rgba(255, 0, 15, 0.2);
        }
      }
      &:nth-child(4n) {
        .ant-select-selection-item {
          background: rgba(255, 184, 0, 0.2);
        }
      }
      &:nth-child(5n) {
        .ant-select-selection-item {
          background: rgba(0, 87, 255, 0.2);
        }
      }
    }
  }
}
.stepOneForm {
  padding-bottom: 120px;
}

.schedulerContainer {
  border-bottom: 1px solid var(--grey-400);
  border-top: 1px solid var(--grey-400);
  padding: var(--spacer-16) 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacer-8);

  .commaWords {
    line-height: 50px;
  }
  .commaInputs {
    --app-input-wrapper-height: auto;
  }
}

.intervalInputs {
  display: flex;
  gap: var(--spacer-8);
  align-items: flex-start;
  .intervalInput {
    flex: 1;
    &._small {
      width: 80px;
      flex: unset;
      --app-input-wrapper-height: 50px
    }
  }
  &._month {
    flex-direction: column;
  }
}

.promptValue {
  @include font-50();
  line-height: 50px;
  padding: 0 var(--spacer-8);
  display: flex;
  gap: var(--spacer-8);
  &._error {
    color: var(--danger-200);
  }
}

.stepTwoContainer {
  display: flex;
  gap: var(--spacer-24);
}

.scrollContainer {
  overflow-y: scroll;
  position: absolute;
  height: calc(100% - 160px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.scrollContainerStepThree {
  height: calc(100% - 155px);
  padding-right: 16px;
  padding-top: var(--spacer-16);
}

.searchWrapper {
  height: 100%;
  border-right: 1px solid #f5f5f5;
  width: 400px;
  position: relative;
  --app-input-wrapper-height: 64px;
}

.currentSearch {
  height: 100%;
  position: relative;
}

.stepTwoEntities {
  flex: 1;
}
.stepTwoCheckBox {
  display: flex;
  flex-direction: column;
  span {
    color: var(--grey-400);
    font-size: 10px;
    line-height: 10px;
  }
}
.entityWrapper {
  padding: 8px;
  border-radius: 12px;

  --app-input-wrapper-height: 50px;
  margin-top: 8px;
  &:nth-child(n) {
    background: rgba(222, 16, 255, 0.1);
  }
  &:nth-child(2n) {
    background: rgba(0, 255, 56, 0.1);
  }
  &:nth-child(3n) {
    background: rgba(255, 0, 15, 0.1);
  }
  &:nth-child(4n) {
    background: rgba(255, 184, 0, 0.1);
  }
  &:nth-child(5n) {
    background: rgba(0, 87, 255, 0.1);
  }
  &:last-of-type {
    margin-bottom: var(--spacer-80);
  }
}
.entityTitle {
  display: flex;
  align-items: center;

  gap: 8px;
  margin-bottom: 10px;

  @include font-50();
}

.entitiesList {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-16);
  margin-bottom: var(--spacer-24);
}

.formFooterButtons {
  width: 100%;
  background: var(--white);
  z-index: 10;
  position: absolute;
  padding: 24px;
  bottom: 0;
  left: 0;
  border-top: 1px solid #efefef;
  display: flex;
  justify-content: space-between;
}

.searchInput {
  padding-right: var(--spacer-16);
  border-bottom: 1px solid #efefef;
}
.selectInput {
  width: calc(100% - 16px);
}

.storageSlug {
  position: absolute;
  padding: var(--spacer-4);
  border: 1px solid var(--grey-300);
  background: var(--white);
  @include font-25();
  top: 40px;
  left: 10px;
  font-size: 10px;
  line-height: 10px;
  border-radius: var(--radius-4);
  width: max-content;
  color: var(--grey-900);
  span {
    color: var(--grey-400);
  }
}

.collapseHeader {
  display: flex;
  gap: 8px;
  align-items: center;
  @include font-50();
  padding-right: var(--spacer-8);
  justify-content: space-between;
}

.collapseHeaderWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}
.collapseHeaderText {
  display: flex;
  flex-direction: column;
}
.slugText {
  font-size: 10px;
  line-height: 10px;
  color: var(--grey-900);
  span {
    color: var(--grey-400);
  }
}

.attributeParams {
  display: flex;
  gap: var(--spacer-4);
  align-items: center;
  margin-top: var(--spacer-4);
}

.attributeContainer {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--grey-400);
  border-radius: var(--radius-8);
  padding: var(--spacer-8);
  margin: 0 0 8px 0;
}

.attributeContainerContent {
  display: flex;
  flex-direction: column;
  @include font-50();
  text-overflow: ellipsis;
}

.storageOptionText {
  display: flex;
  flex-direction: column;
}

.sqlBtn {
  display: flex;
  align-items: center;
  gap: var(--spacer-4);
  border-radius: var(--radius-4);
  padding: var(--spacer-4);
  border: 1px solid rgba(0, 87, 255, 0.2);
  @include font-25();
  transition: 0.2s ease-in-out;
  cursor: pointer;
  .icon {
    color: var(--accent-first);
  }
  &:hover {
    background: rgba(0, 87, 255, 0.2);
  }
}

.sqlTextArea {
  height: 260px;
  margin-bottom: var(--spacer-24);
  & > * {
    height: 100%;
  }
  textarea {
    height: inherit;
  }
}

.helpSql {
  margin-bottom: var(--spacer-16);
}

.textRed {
  color: rgba(255, 0, 15, 0.6);
}

.textBlue {
  color: rgba(0, 87, 255, 0.6);
}

.tooltipSlug {
  display: flex;
  flex-direction: column;
  @include font-25();
  color: var(--grey-400);
  .tooltipSlugData {
    @include font-50();
    &:not(&:last-child) {
      margin-bottom: var(--spacer-8);
    }
  }
}
