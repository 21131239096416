@import "../styles/container";
@import "../styles/media";
@import "../styles/font-styles";

.mainWrapper {
  height: 100vh;
  max-width: 100vw;
  padding: var(--spacer-16);
  margin: 0 auto;
  display: grid;
  grid-template-rows: 74px 1fr;
  gap: var(--spacer-16);
}
.container {
  display: flex;
  gap: var(--spacer-16);
  max-height: inherit;
  position: relative;
}

.content {
  @include baseContainer();
}

.rightBar {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  z-index: 50;
  transition: width 0.2s;
  will-change: width;
  &._none {
    grid-column: unset;
    display: none;
  }
}

.content {
  flex: 1;
  position: relative;
  overflow: hidden;
  &._full {
    grid-column: 1 / 13;
  }
}
