@import "../../styles/container";
@import "../../styles/font-styles";

.authContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.authLanguage {
  position: absolute;
  right: 32px;
  top: 32px;
}

.languageBlock {
  background-color: var(--white);
  @include baseContainer();
  &:hover {
    background-color: var(--white);
  }
}
.authWrapper {
  width: 460px;
  background: var(--white);
  @include baseContainer();
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacer-24);
}
.authHead {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacer-24);
  margin-bottom: var(--spacer-32);
  h1 {
    @include font-600();
  }
}
