@import "../../../../styles/container";
@import "../../../../styles/font-styles";

.modalHeader {
  font-size: 18px;
  font-weight: 500;
  color: #6a6a6a;
  padding-top: var(--spacer-10);
  padding-bottom: var(--spacer-2);
}

.modalDescription {
  font-size: 14px;
  color: #3c3c3c;
  a {
    color: #0057ff;
  }
}

.status {
  color: var(--green);
}

.tableWrapper {
  margin-top: -24px;
  border-left: 1px solid #d9d9d9;
  max-height: 400px;
  overflow-y: auto;

  h3 {
    display: flex;
    gap: var(--spacer-10);
    margin: 14px;
    font-size: 16px;
    color: var(--grey-900);
    font-weight: 400;
  }
}

.rowWrapper {
  padding: 0 var(--spacer-4);
}

.tableTitle {
  @include font-25();
}

.tableContent {
  @include font-25();
}

.ruleWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacer-8);
}

.ruleTag {
  background: rgba(0, 87, 255, 0.2);
  margin-right: unset;
}

.processInfo {
  padding: 10px 8px 6px;
  border-bottom: 1px solid #d9d9d9;
  &Content {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-4);
    font-size: 16px;
    span {
      font-size: 14px;
      color: #9b9b9b;
    }
  }
  & + .processInfo {
    margin-top: 20px;
  }
}

.footer {
  display: flex;
  gap: var(--spacer-4);
  margin: var(--spacer-10) var(--spacer-8);
}

.logoWrapper {
  min-height: 215px;
  position: relative;
}

:global {
  .view-process {
    .ant-modal-footer {
      border-top: 1px solid #9d9d9d;
    }
  }
}
