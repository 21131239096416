@import "../../styles/font-styles";

.input {
  padding: 12px 16px;
  @include font-100();
  border: 1px solid var(--grey-400);
  border-radius: var(--radius-4);
  outline: none;
}

.select {
  width: 100%;
  height: var(--app-input-inner-wrapper-height, 50px);
  @include font-100();
  :global {
    .ant-select-selector {
      height: inherit !important;
      display: flex !important;
      align-items: center !important;
      border: 1px solid var(--grey-400) !important;
      border-radius: var(--radius-4) !important;
    }
  }
}

.inputWrapper {
  min-height: var(--app-input-wrapper-height, 80px);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.iconWrapper {
    position: absolute;
    z-index: 100;
    height: 24px;
    right: 16px;
    top: var(--app-input-icon-top, 13px);
    cursor: pointer;
}

.inputLabel,
.inputError {
  padding: 4px 4px;
  width: 100%;
  font-size: 12px;
}

.inputLabelTop {
  position: absolute;
  z-index: 10;
  left: 16px;
  top: -8px;
  padding: 2px;
  background-color: var(--white);
  @include font-25();
}

.inputError {
  color: var(--danger);
}

.option {
  @include font-100();
}
